/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Link } from 'react-router-dom';
import { Form, FormGroup, Input } from 'reactstrap';
import LinearProgress from '@material-ui/core/LinearProgress';
import QueueAnim from 'rc-queue-anim';
import { ClipLoader } from 'react-spinners';
import * as actions from '../actions';

import { whiteLabel } from 'Whitelabel';

const introText = whiteLabel('introText');
const introTitle = whiteLabel('introTitle');
const logo = whiteLabel('logo');
const headerBackground = whiteLabel('headerBackground');
const highlightColour = whiteLabel('highlightColour');

class Signin extends Component {

   state = {
      email: '',
      password: '',
      loading: false
   }

	/**
	 * On User Login
	 */
   async onUserLogin() {
      if (this.state.email !== '' && this.state.password !== '') {
         this.setState({ loading: true });
         await this.props.signinUserInCognito(this.state, this.props.history);
         this.setState({ loading: false });
      }
   }

	/**
	 * On User Sign Up
	 */
   onUserSignUp() {
      this.props.history.push('/signup');
   }

   renderSignLoad() {
     return (
     <div style={{ width: 200, backgroundColor: highlightColour, color: 'white', height: 45, borderRadius: 5, fontSize: 16, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {this.renderSignLoadInner()}
     </div>
    );
   }

   renderSignLoadInner(){
      if(this.state.loading===true){
         return(
            <ClipLoader
             sizeUnit={"px"}
             size={25}
             color={'white'}
             loading
            />
         );
       }
       else{
           return('Sign In')
         }
   }

   _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      console.log('do validate');
      this.onUserLogin();
    }
  }


   render() {
      const { email, password } = this.state;
      const { loading } = this.props;

      return (
         <QueueAnim type="bottom" duration={2000}>
            <div className="rct-session-wrapper-login" style={{fontSize: '100%', backgroundImage: "url("+headerBackground+")"}}>
               {loading &&
                  <LinearProgress />
               }
               <AppBar position="static" className="login-header">
                  <Toolbar>
                     <div className="auth-container">
                        <div className="d-flex justify-content-between">
                           <div className="session-logo">
                              <Link to="/">
                                 <img src={logo} alt="session-logo" className="img-fluid" width="220" height="70" />
                              </Link>
                           </div>
                           {/*
                           <div>
                              <a className="mr-15 blacktext" style={{paddingTop: 30}} onClick={() => this.onUserSignUp()}>Create New account?</a>
                              <Button variant="contained" className="btn-light" onClick={() => this.onUserSignUp()}>Sign Up</Button>
                           </div>
                           */}
                        </div>
                     </div>
                  </Toolbar>
               </AppBar>
               <div className="session-inner-wrapper">
                  <div className="auth-container">
                     <div className="row row-eq-height">
                        <div className="col"></div>
                        <div className="col-sm-7 col-md-7 col-lg-8">
                           <div className="session-body text-center">
                              <div className="session-head mb-30">
                                 <h2 className="font-weight-bold">{introTitle}</h2>
                                 <p className="mb-0">{introText}</p>
                              </div>
                              <Form>
                                 <FormGroup className="has-wrapper">
                                    <Input
                                       type="mail"
                                       value={email}
                                       name="user-mail"
                                       id="user-mail"
                                       className="has-input input-lg"
                                       placeholder="Email"
                                       onChange={(event) => this.setState({ email: event.target.value })}
                                    />
                                    <span className="has-icon"><i className="ti-email"></i></span>
                                 </FormGroup>
                                 <FormGroup className="has-wrapper">
                                    <Input
                                       value={password}
                                       type="Password"
                                       name="user-pwd"
                                       id="pwd"
                                       className="has-input input-lg"
                                       placeholder="Password"
                                       onKeyDown={this._handleKeyDown}
                                       onChange={(event) => this.setState({ password: event.target.value })}
                                    />
                                    <span className="has-icon"><i className="ti-lock"></i></span>
                                 </FormGroup>
                                 <FormGroup className="mb-15">
                                    <Button
                                       //color={"primary"}
                                       //className="btn-block text-white w-50"
                                       //variant="contained"
                                       disabled={this.state.loading}
                                       //size="large"
                                       onClick={() => this.onUserLogin()}
                                    >
                                       {this.renderSignLoad()}
                            			</Button>
                                 </FormGroup>
                              </Form>

                              {/* <p className="text-muted">By signing up you agree to the</p> */}
                              {/*
                              <p className="mb-0"><a href="/forgetpassword" className="text-muted">Forgot password?</a></p>
                             */}
                           </div>
                        </div>
                        <div className="col"></div>
                     </div>
                  </div>
               </div>
            </div>
         </QueueAnim>
      );
   }
}

// map state to props
const mapStateToProps = (data) => {
   const { user, loading, owner } = data.authUser;
   return { user, loading, owner }
}

export default connect(mapStateToProps, actions)(Signin);
