
// Auth Actions
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_FAILURE = 'SIGNUP_USER_FAILURE';

//addeb by oh7even
export const SIGNUP_USER_CONFIRM = 'SIGNUP_USER_CONFIRM';
export const PROFILE_CONFIRMED = 'PROFILE_CONFIRMED';
export const FORGOTPASSWORD_USER = 'FORGOTPASSWORD_USER';
export const FORGOTPASSWORD_USER_CONFIRM = 'FORGOTPASSWORD_USER_CONFIRM';
export const FORGOTPASSWORD_USER_SUCCESS = 'FORGOTPASSWORD_USER_SUCCESS';
export const CHANGEPASSWORD_USER = 'CHANGEPASSWORD_USER';
export const FORGOTPASSWORD_USER_FAILURE = 'FORGOTPASSWORD_USER_FAILURE';
export const SAVE_ADMIN = 'SAVE_ADMIN';

//Data Actions
export const SAVE_WORKOUTS = 'SAVE_WORKOUTS';
export const SAVE_PURCHASES = 'SAVE_PURCHASES';

export const SAVE_ROW_ORDER = 'SAVE_ROW_ORDER';

export const SAVE_VIDEO_URL = 'SAVE_VIDEO_URL';

export const SAVE_WORKOUTS_ORIG = 'SAVE_WORKOUTS_ORIG';

export const SAVE_PARSED_WORKOUTS = 'SAVE_PARSED_WORKOUTS';

export const FILTER_ON = 'filter_on';
export const SAVE_FOLDERS = 'save_folders';
