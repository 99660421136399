import React, { Component } from 'react'
import { whiteLabel } from 'Whitelabel';
import { uploadThumbnailFile } from 'CommonFunctions';
import LoadingSpinner from 'Assets/images/loading.gif';
import { apiCall } from 'CommonFunctions';
import { Input, Button} from 'reactstrap';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import { connect } from 'react-redux';
import * as actions from '../actions/index';

const highlightColour = whiteLabel('highlightColour');
//const thumbnailImages = whiteLabel('thumbnailImages');

const styles = ({
  text: {
    fontSize: 20,
    color: 'rgba(0,0,0,0.7)',
    fontWeight: '600',
    cursor: 'pointer',
  },
});

const SortableItem = SortableElement((props) => {
  return (
    <div style={{ display: "inline-block"}}>
        <div
        key={props.item.primaryKey}
        className={'xmovie'}
        onClick={(e) => props.pressedThumbnail(props.item, e)}
        style={{
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 20,
          WebkitBoxShadow: '10px 7px 20px -1px rgba(0,0,0,0.31)',
          MozBoxShadow: '10px 7px 20px -1px rgba(0,0,0,0.31)',
          boxShadow: '10px 7px 20px -1px rgba(0,0,0,0.31)',
          marginLeft: 50,
          marginTop: 25,
          marginBottom: 25,
          height: (720 * 0.3) + 50,
        }}
      >
      <img style={{ width: 1280 * 0.3, height: 720 * 0.3, borderTopLeftRadius: 20, borderTopRightRadius: 20 }} src={props.item.img_url} alt="" />
         <div style={{ display: 'flex', height: 50, marginLeft: 15, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
              <div style={{ flex: 4, fontSize: 18, color: highlightColour, opacity: 0.7, fontWeight: '600' }}>{props.item[`level_${props.levelDepth}`]}</div>
              {props.editMode &&
              <div style={{ height: 40, width: 100, backgroundColor: 'grey', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 5}}
                onClick={(e) => {
                        e.stopPropagation();
                        props.deleteFolder(props.item);
                }}>delete </div>
                }
              {props.editMode &&
                <div>
                <input
                    type="file"
                    accept='image/*'
                    onChange={(evt) => props.funcUploadFolderThumbnail(evt, props.item)}
                    onClick={(e) => {
                              e.stopPropagation();
                      }}
                    style={{ width: 100, height: 100 }}
                />
                </div>
              }
          </div>
    </div>
  </div>
    )});

const SortableList = SortableContainer(({items, levelDepth, editMode, pressedThumbnail, deleteFolder, funcUploadFolderThumbnail}) => {
  return (
    <ul style={{ }}>
      {items.map((item, index) => (
          <SortableItem
            key={`item-${item.primaryKey}`}
            levelDepth={levelDepth}
            editMode={editMode}
            index={index}
            item={item}
            disabled={!editMode}
            pressedThumbnail={pressedThumbnail}
            deleteFolder={deleteFolder}
            funcUploadFolderThumbnail={funcUploadFolderThumbnail}
            />
      ))}
    </ul>
  );
});

class HomeMultiScreen extends Component {

  state = {
    editMode: false,
    loadingThumb: false,
    levelDepth: 1,
    filterLevel1: '',
    filterLevel2: '',
    filterLevel3: '',
    nextImageInLine: '',
    individualWorkoutsToDisplay: [],
    //New items
    foldersToShow: [],
    newFolderName: '', //Used for when creating a new folder
    newFolderImageKey: '',//Used for when creating a new folder
    areAnyWorkoutsAvail: false,
  }

  UNSAFE_componentWillMount() {
      this.setupFoldersToShow();
  }


    folderButtonPressed = (level) => {
      //If we press a folder button then we are going back to main folder view and hence we want to make the individual workouts dissapear
      this.setState({ individualWorkoutsToDisplay: [], areAnyWorkoutsAvail: false });

      if (level === 3) {
        this.setState({ levelDepth: 3, filterLevel3: '',  }, ()=> this.folderButtonPressedSet(level))
      }
      if (level === 2) {
        this.setState({ levelDepth: 2, filterLevel2: '', filterLevel3: '' }, ()=> this.folderButtonPressedSet(level))
      }
      if (level === 1) {
        this.setState({ levelDepth: 1, filterLevel1: '', filterLevel2: '', filterLevel3: '' }, ()=> this.folderButtonPressedSet(level))
      }
    }

    folderButtonPressedSet = (level) => {

      this.setupFoldersToShow();

    }


/*This is the Home>Fitness etc... up the top*/
    folderDirectory = () => {
        return (
        <div style={{ flexDirection: 'row', marginLeft: 20, marginTop: 40, marginBottom: 8 }}>
          <span onClick={() => this.state.levelDepth === 1 ? null : this.folderButtonPressed(1)}><span style={styles.text}>{'Home'}</span></span>
          <span style={styles.text}>{this.state.filterLevel1 !== '' ? ' > ' : null}</span>
          <span onClick={() => this.folderButtonPressed(2)}><span style={styles.text}>{this.state.filterLevel1 !== '' ? `${this.state.filterLevel1}` : null}</span></span>
          <span style={styles.text}>{this.state.filterLevel2 !== '' ? ' > ' : null}</span>
          <span onClick={() => this.folderButtonPressed(3)}><span style={styles.text}>{this.state.filterLevel2 !== '' ? `${this.state.filterLevel2}` : null}</span></span>
          <span style={styles.text}>{this.state.filterLevel3 !== '' ? ' > ' : null}</span>
          <span><span style={styles.text}>{this.state.filterLevel3 !== '' ? `${this.state.filterLevel3}` : null}</span></span>
        </div>
      );
    }


    //This is called when a new folder is added
    uploadNewFolderImage = async (evt) => {
      this.setState({ loadingThumb: true });
      const v = await uploadThumbnailFile(evt, this.uploadProgress, true); //Last param is to tell it we want to upload to the folders directory
      if (v.success) {
        //console.log('uploaded success', v.key)
        this.setState({ newFolderImageKey: v.key })
      } else {
        //console.log('uploaded fail', v)
      }
      this.setState({ loadingThumb: false });
    }

  //this is the function for when changing a current image
  funcUploadFolderThumbnail = async (evt, item) => {
    this.setState({ loadingThumb: true });
    //Upload the new image
    const v = await uploadThumbnailFile(evt, this.uploadProgress, true); //Last param true is to tell it we want to upload to the folders directory
    if (v.success) {
      //console.log('uploaded success', v.key)
      //Now save data to database
      await apiCall('editFolderImage', { databaseKey:  item.primaryKey, imageFileKey:  v.key });
      //Refresh everything locally
      await this.props.refreshData()
      this.setupFoldersToShow()
    } else {
      //console.log('uploaded fail', v)
    }

    this.setState({ loadingThumb: false });
  }

  setThumbnailsToView = () => {
    this.setState({levelDepth: this.state.levelDepth+1 }, ()=>this.setupFoldersToShow())
  }


  pressedThumbnail = (item) => {
    this.setState({ [`filterLevel${this.state.levelDepth}`]: item[`level_${this.state.levelDepth}`] }, ()=> this.setThumbnailsToView())
  }

  editModeButton = () => {
    if (this.props.authUser.admin) {
      return (
        <div style={{marginTop: 20, cursor: 'pointer' }} onClick={()=>this.setState({ editMode: !this.state.editMode})}> Edit Mode </div>
      )
    } else return null
  }


  sortByKey(array, key) {
      return array.sort(function(a, b) {
          var x = a[key]; var y = b[key];
          return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      });
  }

  setupFoldersToShow = () =>{

    let data = []
    if (this.state.levelDepth === 1) {
      //Main level
      data = this.props.reducerData.folderData.filter(item => item.level_2 === '' && item.level_3 === '')
    }
    if (this.state.levelDepth === 2) {
      //Second level
      data = this.props.reducerData.folderData.filter(item => item.level_3 === '' && item.level_1 === this.state.filterLevel1 && item.level_2 !== '')
    }
    if (this.state.levelDepth === 3) {
      data = this.props.reducerData.folderData.filter(item => item.level_1 === this.state.filterLevel1 && item.level_2 === this.state.filterLevel2 && item.level_3 !== '')
    }

    //If no data then no more folder depth so must show individual items
    if (data.length === 0) {
      this.showIndividualWorkouts();
    }


    this.sortByKey(data, `order_l${this.state.levelDepth}`)
    this.setState({ foldersToShow: data })
  }



  showIndividualWorkouts = () => {
    const dataTmp = this.props.reducerData.workouts
                .filter(i => this.state.filterLevel1 === '' ? true : i.level_1 === this.state.filterLevel1)
                .filter(i => this.state.filterLevel2 === '' ? true : i.level_2 === this.state.filterLevel2)
                .filter(i => this.state.filterLevel3 === '' ? true : i.level_3 === this.state.filterLevel3);

    this.setState({ individualWorkoutsToDisplay: this.props.getMovies(dataTmp), areAnyWorkoutsAvail: dataTmp.length>0})

  }


addFolderButton = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', flexDirection: 'column', marginLeft: 100, marginTop: 25, width: 200, height: 200, backgroundColor: 'rgba(0,0,0,0.3)'}}>
      <Input
          onChange={(e) => this.setState({ newFolderName: e.target.value })}
          value={this.state.newFolderName}
          style={{ fontSize: 12, width: 200, height: 30 }}
          maxLength={40}
          autoComplete='none'
          id='inputA'
          placeholder='Folder name'
      />

      <input
          type="file"
          accept='image/*'
          onChange={(evt) => this.uploadNewFolderImage(evt)}
          style={{ width: 100, height: 100 }}
      />
      <span style={{ color: this.state.newFolderImageKey === '' ? 'black' : 'green' }}>{this.state.newFolderImageKey === '' ? 'No Image Uploaded' : 'Image Uploaded'}</span>
      <Button variant="contained" color="primary" style={{ }} onClick={()=>this.addFolder()}>Add Folder</Button>

    </div>

  )
}

addFolder = async () => {

  if (this.state.newFolderName === '') {
    alert('You must enter a folder name first')
    return
  }
  if (this.state.newFolderImageKey === '') {
    alert('You must upload an image first')
    return
  }


  const bodyData = {
    primaryKey: Date.now().toString(),
    level_1: this.state.levelDepth === 1 ? this.state.newFolderName : this.state.filterLevel1,
    level_2: this.state.levelDepth === 2 ? this.state.newFolderName : this.state.filterLevel2,
    level_3: this.state.levelDepth === 3 ? this.state.newFolderName : this.state.filterLevel3,
    [`order_l${this.state.levelDepth}`]: this.state.foldersToShow.length + 1,
    image: this.state.newFolderImageKey,
  }

  this.setState({ loadingThumb: true })
  await apiCall('addFolder', bodyData);
  await this.props.refreshData()
  this.setupFoldersToShow()
  this.setState({ newFolderName: '', newFolderImageKey: ''})
  this.setState({ loadingThumb: false })
}

  deleteFolder = async (item) => {
    const r = window.confirm('If you delete this folder, you wont be able to view the workouts below unless you add the folder back or you move the workourts to a new folder');
    if(r === true)
    {
      this.setState({ loadingThumb: true})
      await apiCall('deleteFolder', {primaryKey: item.primaryKey});
      await this.props.refreshData()
      this.setupFoldersToShow()
      this.setState({ loadingThumb: false})
    }
  }


  onSortEnd = ({oldIndex, newIndex}) => {

    //If no change in order, don't continue
    if(newIndex===oldIndex) {return;}

    const newOrder_tmp = arrayMove(this.state.foldersToShow, oldIndex, newIndex)
    const newOrder = newOrder_tmp.map((item, index) => {
      item[`order_l${this.state.levelDepth}`] = index + 1
      return item;
    })
    this.setState({foldersToShow: newOrder});

    //Now just loop through and save each one individually
    this.saveRowOrder(newOrder)
   };


   async saveRowOrder(newOrder) {

     await Promise.all(newOrder.map(async (item) => {
       	await apiCall('saveFolderRowOrder', {dbColumn: `order_l${this.state.levelDepth}`, primaryKey: item.primaryKey, newOrder: item[`order_l${this.state.levelDepth}`]});
        return
       }));
     this.setupFoldersToShow()

   }

   render() {
      return (
        <>
        {this.state.loadingThumb &&
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', position: 'absolute', backgroundColor: 'rgba(0,0,0,0.2)' }}>
              <img src={LoadingSpinner} style={{ width: '3rem' }} alt="loading..." />
          </div>
        }
        {this.folderDirectory()}
        <div style={{ flexWrap: 'wrap', minHeight: 720 * 0.3 * 3 }}>
        {this.state.foldersToShow.length > 0 &&
          <SortableList
            items={this.state.foldersToShow}
            onSortEnd={this.onSortEnd}
            editMode={this.state.editMode}
            levelDepth={this.state.levelDepth}
            axis="xy"
            pressedThumbnail={this.pressedThumbnail}
            deleteFolder={this.deleteFolder}
            funcUploadFolderThumbnail={this.funcUploadFolderThumbnail}
            />
          }
          {!this.state.areAnyWorkoutsAvail && this.state.levelDepth <= 3 && this.state.editMode && this.addFolderButton()}
          {this.state.individualWorkoutsToDisplay}
        </div>

        {this.editModeButton()}
        </>
      )
   }
}

const mapStateToProps = ({ reducerData, authUser }) => {
  return {
    reducerData: reducerData,
    authUser: authUser
  }
}

export default connect(mapStateToProps, actions)(HomeMultiScreen);
