import React, { Component } from 'react';
//import screenfull from 'screenfull';
//import Hls from "hls.js"; //https://blog.logrocket.com/a-comprehensive-guide-to-video-playback-in-react/

import { connect } from 'react-redux';
import * as actions from '../actions/index';

import { Replay } from 'vimond-replay'; //https://vimond.github.io/replay/#/
//https://vimond.github.io/replay/#/controls/quality-selector
import 'vimond-replay/index.css';
import HlsjsVideoStreamer from 'vimond-replay/video-streamer/hlsjs'; //https://github.com/video-dev/hls.js/

import Navbar from 'Components/Navbar';

import Modal from 'Components/UI/Modal';
import ModalVideo from 'Components/UI/ModalVideo';
import MovieDetails from 'Components/Movie/MovieDetails';

import Header from 'Components/Header';
import Footer from 'Components/Footer';

import LoadingSpinner from 'Assets/images/loading.gif';

import HomeMultiScreen from 'Components/thumbnails_images_render';

import { movieRows } from 'Components/movieRow';

import { apiCall } from 'CommonFunctions';

import { whiteLabel } from 'Whitelabel';
const multiTagLevels = whiteLabel('multiTagLevels');

const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));


const replayOptions = {
  videoStreamer: {
    hlsjs: {
      customConfiguration: {
        //debug: true,
        capLevelToPlayerSize: false,
        //maxBufferLength: 45,
        //startLevel: 1, //0 being lowest bitrate
      }
    }
  }
};

class Layout extends Component {

  constructor(props){
     super(props);
     this.escFunction = this.escFunction.bind(this);
  }

  state = {
    /** Toggles the modal when a movie is clicked. */
    videoDescriptionModal: false,
    /** Holds the movie information for a single movie. */
    movieOverview: {},
    //Makes video visible or // NOTE:
    //showVideo: true,
    loadingVideoCall: false,
    videoPlayerModalVisible: false,
    videoSourceHLS: null,
    paused: true,
    isPDFOpen: false,
    loadingGetUserData: true,
    navOrigCSS: '',

  }


    preLoadImages(data) {
      data.forEach((picture) => {
          new Image().src = picture.img_url
      });
    }

    refreshData = async () => {
      const retData = await apiCall('getUserData', {admin: this.props.authUser.admin})
      //console.log('retData', retData)
      this.props.saveFolderData(retData.resultsFolderThumbnails);

      this.preLoadImages(retData.resultsFolderThumbnails);
      this.props.savePurchases(retData.purchases)
      const availWorkouts = retData.parsedWorkouts;
      //console.log('availWorkouts', availWorkouts)
      this.props.saveRowOrder(retData.resultsRowOrder);
      this.props.saveVideoURL(retData.videoURL);
      localStorage.setItem('videoURL', retData.videoURL);
      console.log('retData.videoURL', retData.videoURL)
      this.props.saveWorkouts(availWorkouts);
      this.props.saveWorkoutsOrig(availWorkouts); //Needed for filter
      this.setState({ loadingGetUserData: false });

    }

  UNSAFE_componentWillMount() {
    this.refreshData();
  }

  componentDidMount(){
    document.addEventListener("keydown", this.escFunction, false);
    window.addEventListener("scroll", this.handleScroll);

  }
  componentWillUnmount(){
    document.removeEventListener("keydown", this.escFunction, false);
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY > 50) {
      document.querySelector(".navigation").className = "navigation backgroundDark";
    } else {
      document.querySelector(".navigation").className = "navigation";
    }
  };

  escFunction(event){
   if(event.keyCode === 27) {//ESC button
     if (this.state.videoPlayerModalVisible) {
       this.toggleVideoModal(false);
     } else {
       this.closeModal();
     }
   }
  }

  changeLoadingVideoCallState(state) {
    this.setState({ loadingVideoCall: state });
  }

  handleToggleModal = (movie) => {
    this.setState({
      movieOverview: movie,
      videoDescriptionModal: true,
    });
  }

  closeModal = () => {
    this.setState({ videoDescriptionModal: false, isPDFOpen: false });
  }

  toggleVideoModal = (visible) => {
    this.setState({ videoPlayerModalVisible: visible });
    if (!visible) {
      this.setState({ videoSourceHLS: null });
      if (isSafari) {
        document.getElementById('videoMain').pause();
      }

    }
  }


//Safari plays HLS fine so if in safari pass HLS through source, if not use hls method
  startVideo(videoUrl) {
    this.toggleVideoModal(true);
    this.setState({ videoSourceHLS: videoUrl });
    //this.setState({ videoSourceHLS: 'https://d1u4oz0uuj663i.cloudfront.net/output/hls/netfit/WEEK_2__SA_WORKOUT.m3u8' });

    if (isSafari) {
      const videoMain = document.getElementById('videoMain');
      videoMain.src = videoUrl;
      videoMain.play();
    }
  }

/*
This returns all rows and columns
*/
  getMovies = (workouts = this.props.reducerData.workouts) => {
    let movies
    // Call getMoviesRows function only when we get the data back
    // from the API through redux
    if (workouts && this.props.reducerData.rowOrder) {

      movies = movieRows(workouts, this.handleToggleModal, this.props.reducerData.rowOrder);

      if (movies.length === 0 && this.props.reducerData.filterOn) {
        return (
          <div style={{display: 'flex', flex: 1, height: 50, alignItems: 'center',  justifyContent: 'center'}}>
          <span>No search results</span>
          </div>
        )
      }
    }
    return (
      <>
        {movies}
      </>
    )
  }

  togglePDFViewer = (status) => {
    this.setState({ isPDFOpen: status });
  }

  render() {
    return (
      <div >
        {/*  Drop down menu  */}
        <Navbar showMovies={this.onSearchHandler} />

        {/*  Header and movie thumbnails  */}
        <div className="movie-container">
          <Header />


          <div className="movieShowcase" id="movieShowcase">

          {this.state.loadingGetUserData &&
            <div style={{ display: 'flex', flex: 1, justifyContent: 'center', marginTop: 50}}>
              <img src={LoadingSpinner} style={{ width: '3rem', marginBottom: 20 }} alt="loading..." />
            </div>
            }
            {/*Code to either show multi level buttons or the thumbnails*/}
            {(!multiTagLevels || this.props.reducerData.filterOn === true) && this.getMovies()}
            {(multiTagLevels && this.props.reducerData.workouts !== undefined && this.props.reducerData.filterOn !== true) &&
              <HomeMultiScreen refreshData={this.refreshData} getMovies={this.getMovies}/>}

          </div>
          <Footer />

        </div>

        {/*  -- This is just the pop up when you click on a thumbnail--  */}
        <Modal
          show={this.state.videoDescriptionModal}
          modalClosed={this.closeModal}
          movie={this.state.movieOverview}>

          <MovieDetails
            startVideo={this.startVideo.bind(this)}
            movie={this.state.movieOverview}
            closeModal={this.closeModal}
            loadingVideoCallState={this.state.loadingVideoCall}
            changeLoadingVideoCallState={this.changeLoadingVideoCallState.bind(this)}
            isPDFOpen={this.state.isPDFOpen}
            togglePDFViewer={this.togglePDFViewer}
            />
        </Modal>



      {/*  -- This is actual video modal, just using html video --  */}
         <ModalVideo
          show={this.state.videoPlayerModalVisible}
          toggleVideoModal={this.toggleVideoModal}
          >
          <div style={{ width: '50%', height: '50%'}}>
             <button onClick={() => this.toggleVideoModal(false)} style={{ borderWidth: 0, height: 100, width: '100%', backgroundColor: 'rgba(0,0,0,0)'}}/>

             {!isSafari &&
             <Replay
               source={this.state.videoSourceHLS}
               initialPlaybackProps={{ isPaused: false }}
               options={replayOptions}
             >
               <HlsjsVideoStreamer />
             </Replay>
             }
             {isSafari &&
               <video
                 className="videoCanvas"
                 id='videoMain'
                 ref={player => (this.player = player)}
                 autoPlay={true}
                 width='100%'
                 height='100%'
                 controls
                 controlsList='nodownload'
               />
             }

            </div>
          </ModalVideo>
        {/*  -- This is actual video model, just using html video--  */}
      </div>

    );
  }

}

const mapStateToProps = ({ reducerData, authUser }) => {
  return {
    reducerData: reducerData,
    authUser: authUser
  }
}

export default connect(mapStateToProps, actions)(Layout);
