import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
//import { whiteLabel } from 'Whitelabel';
import { apiCall } from 'CommonFunctions';
import DatePicker from "react-datepicker";
import moment from 'moment';

//const restrictedList = whiteLabel('restrictedList');

export default class RestrictedList extends Component {

  state = {
    purchaseList: [],
    origPurchaseList: [],
    addEmail: '',
    searchEmail: '',
    date: new Date(),
    sku: 'premium'
  }



  async loadPurchaseList() {
    const ret = await apiCall('readAllPurchases');
    this.setState({ origPurchaseList: ret.res, purchaseList: ret.res });
  }

  async componentWillMount() {
    await this.loadPurchaseList();
  }


  filterBox = () => {
    return (
      <div style={{ width: 300, height: 70, marginTop: 10, marginBottom: 10 }}>
      <span style={{ fontSize: 13 }}>Search Email</span>
      <Input
          onChange={(e) => this.filterList(e.target.value,)}
          style={{ fontSize: 12 }}
          autoComplete='none'
          value={this.state.searchEmail}
      />
      </div>
    );
  }

  filterList(text) {
    this.setState({ searchEmail: text });
    const tmp = this.state.origPurchaseList.filter(function(item) {
     return item.user_id.toLowerCase().search(text.toLowerCase()) >= 0;
   });
   //If input is empty then set state back to full list
   this.setState({ purchaseList: text === '' ? this.state.origPurchaseList : tmp });
  }

  removePurchase = async (uuid) => {
    const r = window.confirm('Do you really want to delete this purchase?');
    if(r === true)
    {
      await apiCall('deletePurchase', { uuid: uuid });
      await this.loadPurchaseList();
      this.clearAllInputs();
    }
  }

  clearAllInputs() {
    this.setState({ addEmail: '', searchEmail: ''});
  }

  addPurchase = async () => {
    await apiCall('addPurchase', { email: this.state.addEmail.trim(), sku: this.state.sku, last_day: moment(this.state.date).format("YYYY-MM-DD") });
    await this.loadPurchaseList();
    this.clearAllInputs();
  }

  render() {

      return (
        <div style={{ borderWidth: 1, borderStyle: 'solid', borderRadius: 5, padding: 10, marginRight: 20, marginBottom: 100, width: 500 }}>
          <span style={{ marginBottom: 5 }}>Purchases</span>
          <div style={{ display: 'flex', borderStyle: 'solid', borderRadius: 5, padding: 5, borderWidth: 0.2, borderColor: 'rgba(0,0,0,0.3)' }}>
            <Button variant="contained" color="primary" style={{marginBottom: 5}} onClick={()=>this.addPurchase()}>Add Purchase</Button>
            <div style={{display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
              <Input
                  onChange={(e) => this.setState({ addEmail: e.target.value })}
                  style={{ width: 300, fontSize: 12, marginBottom: 5 }}
                  value={this.state.addEmail}
                  autoComplete='none'
              />
              <DatePicker
                dateFormat="dd-MM-yyyy"
                autoComplete='none'
                selected={this.state.date}
                onChange={(e)=>{
                  this.setState({ date:e });
                }}
                />
                <Input
                  placeholder={'sku'}
                  style={{width: 100, marginTop: 5, fontSize: 12}}
                  onChange={(e) => this.setState({ sku: e.target.value })}
                  value={this.state.sku}
                  disabled={true}
                />
              </div>
          </div>
          {this.filterBox()}
          <span style={{ fontSize: 14, fontWeight: 600 }}>Click on a row to delete (displays top 100 from search only)</span>

          <div style={{ display: 'flex', flexDirection: 'row', minWidth: 400, paddingTop: 10, paddingBottom: 0, fontWeight: 600 }}>
            <div style={{width: 300, fontSize: 12}}>Email</div>
            <div style={{width: 75, fontSize: 12}}>SKU</div>
            <div style={{width: 75, fontSize: 12}}>Last Day</div>
          </div>
          <div style={{ height: 300, overflow: 'scroll'}}>
          {this.state.purchaseList.slice(0, 100).map((item, index) => {
            return (
              <div onClick={() => this.removePurchase(item.uuid)} key={item.uuid} style={{ display: 'flex', flexDirection: 'row', minWidth: 400, paddingTop: 10, paddingBottom: 10, borderTopWidth: 0.5, borderTopColor: 'rgba(0,0,0,0.25)', borderTopStyle: 'solid'}}>
                <div style={{width: 300, fontSize: 12}}>{item.user_id}</div>
                <div style={{width: 75, fontSize: 12}}>{item.sku}</div>
                <div style={{width: 75, fontSize: 12}}>{item.last_day}</div>
              </div>
            )
          })}
          </div>
        </div>
      )

  }
}
