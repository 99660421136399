import Amplify, { Auth } from 'aws-amplify';
import { whiteLabel } from 'Whitelabel';
import {NotificationManager} from 'react-notifications';
import { apiCall } from 'CommonFunctions';

import {
   LOGIN_USER,
   LOGIN_USER_SUCCESS,
   LOGIN_USER_FAILURE,
   LOGOUT_USER,
   SAVE_ADMIN,
} from './types';

const awsConfig = whiteLabel('awsConfig');
const restrictedList = whiteLabel('adminPages').whitelist;

export const checkRestrictedList = async () => {
  const areWeAllowed = await apiCall('checkRestrictedList');
  return restrictedList ? areWeAllowed.results : true;
}


Amplify.configure(awsConfig);

//added by oh7even
export const signinUserInCognito = (user, history) => async function (dispatch) {
   try {
      dispatch({ type: LOGIN_USER });
      user.email = user.email.toLowerCase();
      const data = await Auth.signIn(user.email, user.password);
      const userInfo = await Auth.currentAuthenticatedUser();


      //Save whether we are admin or not
      if (userInfo.signInUserSession.idToken.payload['cognito:groups'] !== undefined) {
        if (userInfo.signInUserSession.idToken.payload['cognito:groups'][0] === 'admin') {
          //console.log('in admin group');
          localStorage.setItem("admin", true);
          dispatch({ type: SAVE_ADMIN, payload: true});
        }
      } else {
        //console.log('not in admin group');
        localStorage.setItem("admin", false);
        dispatch({ type: SAVE_ADMIN, payload: false});
      }

      const accessToken = data.signInUserSession.accessToken.jwtToken;
      const idToken = data.signInUserSession.idToken.jwtToken;
      localStorage.setItem("user_id", user.email);
      localStorage.setItem("user_name", userInfo.attributes.name);
      localStorage.setItem('access_token', accessToken);
      localStorage.setItem('auth_token', idToken);

      const areWeAllowed = await checkRestrictedList();
      //console.log('areWeAllowed', areWeAllowed);
      if (areWeAllowed === false) {
        alert('Please contact support to make sure you have been added to the platform');
        dispatch({ type: LOGIN_USER_FAILURE });
      } else {
        dispatch({ type: LOGIN_USER_SUCCESS, payload: user.email});
        history.push('/');
      }

   } catch (error) {
      dispatch({ type: LOGIN_USER_FAILURE });
      alert(error.response === undefined ? error.message : error.response.data);
   }
}

export const logoutUserFromCognito = () => async function (dispatch) {
  console.log('in logoutUserFromCognito');
   try {

      await Auth.signOut();

      dispatch({ type: LOGOUT_USER });

      localStorage.removeItem('user_id');
      localStorage.removeItem("user_name");
      localStorage.removeItem('access_token');
      localStorage.removeItem('auth_token');
      //NotificationManager.success('User Logout Successfully');
   } catch (error) {
      alert(error.response === undefined ? error.message : error.response.data);
   }
}
