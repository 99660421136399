import React, { Component } from 'react';
import { Button, Input, Label } from 'reactstrap';
import { whiteLabel } from 'Whitelabel';
import { apiCall } from 'CommonFunctions';
import LoadingSpinner from 'Assets/images/loading.gif';
import { TableWorkouts } from '../components/tableWorkouts.js';
import AddEditWorkoutModal from '../components/AddEditWorkoutModal_ORIG';
import arrayMove from 'array-move';

const blankWorkout = {
  col_order: '',
  date: '',
  description: '',
  draft: 1,
  portrait: 0,
  timer: 0,
  tracking: 0,
  duration: '',
  equipment: '',
  intensity: '',
  link_id: '',
  store_sku: '',
  tag: '',
  thumbnailUrl: '',
  title: '',
  trainer: '',
  type: '',
  uid: '',
  vimeo_id: '',
  video_key: '-',
  image_key: '-',
  attachment_key: '-',
  level_1: '',
  level_2: '',
  level_3: '',
  folderSelection: {l1: [], l2: [], l3: []},
}

export default class Videos extends Component {

  state = {
    workouts: [],
    origWorkouts: [],
    loading: true,
    modalVisible: false,
    selectedWorkout: [],
    videoList: null,
    trackingFirstLoad: true
  }

  async componentWillMount() {
    await this.loadWorkouts();
    await this.loadTrackingData();
    //this.loadPrograms();

    //Load list of tags
    apiCall('getUserData', {admin: this.props.admin})
    .then((retData) => {
      this.setState({ resultsFolderThumbnails: retData.resultsFolderThumbnails})
      //this.setupFolderSelection();
      //Getting the row order from the server
        const unique = retData.resultsRowOrder.map(item => {
          return item.tag;
        });
        this.setState({ items: unique });
    });

    this.loadVideos();

  }

  loadVideos = async () => {
    this.setState({ videoList: null, origVideoList: null });
    const x = await apiCall('getVideoList');
    this.setState({ videoList: x.results, origVideoList: x.results });
  }

  onSortEnd = ({oldIndex, newIndex}) => {
     this.setState(({items}) => ({
       items: arrayMove(items, oldIndex, newIndex),
     }),()=>this.saveRowOrder());
   };

   async saveRowOrder() {
     //console.log('saving row order', this.state.items)
     await apiCall('saveRowOrder', {items: this.state.items});
   }

  async updateUniqueTags() {
    //Find our unique tags based on current workouts
    //Then filter these 'unique' tags based off our old ordering (this.state.items)
    //This helps keep the order based on what we already have

    const unique = [...new Set(this.state.workouts.map(item => item.tag))];


    //New code
    const order = this.state.items
    let newOrder = [];
    let newUniqueValues = unique;
    for (let i = 0; i < order.length; i++) {
      for (let j = 0; j < newUniqueValues.length; j++) {
        const inc = newUniqueValues[j].toLowerCase().includes(order[i].toLowerCase());
        //console.log('inc', inc, newUniqueValues[j], order[i]);
        if (inc) {
          newOrder.push(newUniqueValues[j]);
          newUniqueValues = newUniqueValues.filter(item => item !== newUniqueValues[j]);
        }
      }
    }

    const finalOrder = [...newOrder, ...newUniqueValues];


    //Set state
    this.setState({ items: finalOrder });
  }

  loadTrackingData = async () => {
    const ret = await apiCall('getMasterTrackingAdmin');
    this.setState({ trackingData: ret.results });
  }

  async loadWorkouts() {
    const ret = await apiCall('getAllWorkouts');
    this.setState({ workouts: ret.workouts, origWorkouts: ret.workouts, loading: false });
  }

  filterBox = (label, filterBy, listState, origListState) => {
    return (
      <div style={{ width: 300, height: 70, padding: 10, marginBottom: 10 }}>
      <span style={{ fontSize: 13 }}>{label}</span>
      <Input
          onChange={(e) => this.filterList(e.target.value, filterBy, listState, origListState)}
          style={{ fontSize: 12 }}
          autoComplete='none'
      />
      </div>
    );
  }

  filterList(text, filterBy, listState, origListState) {
    const tmp = this.state[origListState].filter(function(item) {
      return item[filterBy].toLowerCase().search(text.toLowerCase()) >= 0;
    });
    //If input is empty then set state back to full list
    this.setState({ [listState]: text === '' ? this.state[origListState] : tmp });
  }



    rowPressed = (uid) => {
      //Get the workout we selected from cliking on a row
      const selectedWorkout =  this.state.workouts.filter(function(item) {
        return item.uid === uid;
      });
      this.setState({ selectedWorkout: selectedWorkout[0] }, ()=>this.toggleModal(true));
    }

    toggleModal = (visible) => {
      this.setState({ modalVisible: visible });
    }

    changeText = (text, state) => {
      this.setState({ selectedWorkout: {
            ...this.state.selectedWorkout,
            [state]: text
          }},()=>this.fixIntegrity());
    }

    //Fix up values in selecteWorkout based off other values, ie if if not purchasbale, set store_sku to '-'
    fixIntegrity = () => {
      const link_id = this.state.selectedWorkout.type !== 'linked' ? '-' : this.state.selectedWorkout.link_id;
      const store_sku = !(this.state.selectedWorkout.type === 'purchasable-movie' || this.state.selectedWorkout.type === 'purchasable-no_movie') ? '-' : this.state.selectedWorkout.store_sku;

      this.setState({ selectedWorkout: {
            ...this.state.selectedWorkout,
            link_id: link_id,
            store_sku: store_sku,
          }}, ()=> {
            //Update our folder selection drop downs
            //console.log(this.state.selectedWorkout)
            //this.setupFolderSelection()
          });
    }

  addWorkoutButton() {
    const date = Math.round(Date.now() / 1000);
    blankWorkout.date = date;
    blankWorkout.uid = `W-${this.props.owner}-${date}`;
    blankWorkout.type = 'free';
    blankWorkout.link_id = '-';
    blankWorkout.store_sku = '-';
    this.setState({ selectedWorkout: blankWorkout }, ()=>this.toggleModal(true))
  }


  deleteWorkout = async () => {
    const r = window.confirm('Do you really want to delete this video?');
    if(r === true)
    {
      this.setState({ loading: true });
      await apiCall('deleteWorkout', {uid: this.state.selectedWorkout.uid});
      this.toggleModal(false);
      await this.loadWorkouts();
      await this.updateUniqueTags();
      this.saveRowOrder();
    }

  }

  closeModal = async (refresh = false) => {
    this.setState({ modalVisible: false });
    if (refresh) {
      await this.loadWorkouts();
      await this.loadTrackingData();
      await this.updateUniqueTags();
      this.saveRowOrder();
    }
    setTimeout(() => {
      this.setState({ trackingFirstLoad: true })
      //console.log('adminpanel.js - trackingFirstLoad:true')
    }, 50);
  }

  setTrackingFirstLoadFalse = () => {
    this.setState({ trackingFirstLoad: false})
  }

  setTrackingFirstLoadTrue = () => {
    this.setState({ trackingFirstLoad: true})
  }

  render (){
    if (this.state.loading) {
      return <img src={LoadingSpinner} style={{ width: '6rem', marginBottom: 20 }} alt="loading..." />
    }

    return (
      <div>
        <div style={{ display: 'flex' }}>
          <Button variant="contained" color="primary" style={{ alignSelf: 'center', width: 120, height: 40}} onClick={()=>this.addWorkoutButton()}>New Wokout</Button>
          {this.filterBox('Filter Titles', 'title', 'workouts', 'origWorkouts')}
          {this.filterBox('Filter Tags', 'tag', 'workouts', 'origWorkouts')}
        </div>

        <AddEditWorkoutModal
          show={this.state.modalVisible}
          closeModal={this.closeModal}
          selectedWorkout={this.state.selectedWorkout}//The workout I clicked on
          videoList={this.state.videoList} //The list of uploaded videos
          origWorkouts={this.state.origWorkouts}
          changeText={this.changeText}
          deleteWorkout={this.deleteWorkout}
          resultsFolderThumbnails={this.state.resultsFolderThumbnails}
          folderSelection={this.state.folderSelection}
          trackingData={this.state.trackingData}
          trackingFirstLoad={this.state.trackingFirstLoad}
          setTrackingFirstLoadFalse={this.setTrackingFirstLoadFalse}
          setTrackingFirstLoadTrue={this.setTrackingFirstLoadTrue}
        />

      <TableWorkouts data={this.state.workouts} rowPressed={this.rowPressed}/>

      </div>
    )
  }

}
