import React, { Component, useState, useEffect } from 'react';
import Auxx from '../../../hoc/Auxx';
import { Input, Button } from 'reactstrap';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import LoadingSpinner from 'Assets/images/loading.gif';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { apiCall, deleteAttachment, deleteThumbnail  } from 'CommonFunctions';
import { whiteLabel } from 'Whitelabel';

import UploadAttachment from '../components/uploadAttachment.js';
import UploadThumbnail from '../components/uploadThumbnail.js';
import Tracking from '../components/tracking.js';

const pdf = whiteLabel('pdf');
const multiTagLevels = whiteLabel('multiTagLevels');
const trackingAndTimer = whiteLabel('trackingAndTimer');

const inputHeight = 23;

const data = {
  vimeo_id:    { type: 'text',     maxCharL: 12,   widthInput: 150, heightInput: inputHeight, title: 'Vimeo ID', value: 'vimeo_id', numbersOnly: true },
  video_key:   { type: 'text',                     widthInput: 80,  heightInput: inputHeight, title: 'Video', value: 'video_key' },
  date:        { type: 'date',                     widthInput: 80,  heightInput: inputHeight, title: 'Date', value: 'date' },
  title:       { type: 'text',     maxCharL: 40,   widthInput: 220, heightInput: inputHeight, title: 'Title', value: 'title' },
  description: { type: 'text',     maxCharL: 5000, widthInput: 500, heightInput: 200,         title: 'Description', value: 'description', multiline: true },
  intensity:   { type: 'text',     maxCharL: 12,   widthInput: 100, heightInput: inputHeight, title: 'Intensity', value: 'intensity' },
  equipment:   { type: 'text',     maxCharL: 20,   widthInput: 200, heightInput: inputHeight, title: 'Equipment', value: 'equipment' },
  duration:    { type: 'text',     maxCharL: 10,   widthInput: 100, heightInput: inputHeight, title: 'Duration', value: 'duration' },
  trainer:     { type: 'text',     maxCharL: 30,   widthInput: 200, heightInput: inputHeight, title: 'Trainer', value: 'trainer' },
  tag:         { type: 'text',     maxCharL: 40,   widthInput: 160, heightInput: inputHeight, title: 'Tag', value: 'tag' },
  type:        { type: 'text',     maxCharL: 10,   widthInput: 80,  heightInput: inputHeight, title: 'Type', value: 'type' },
  store_sku:   { type: 'text',     maxCharL: 10,   widthInput: 80,  heightInput: inputHeight, title: 'Store SKU', value: 'store_sku' },
  link_id:     { type: 'text',     maxCharL: 10,   widthInput: 80,  heightInput: inputHeight, title: 'Link ID', value: 'link_id' },
  draft:       { type: 'checkBox',                                                            title: 'Draft', value: 'draft' },
  portrait:    { type: 'checkBox',                                                            title: 'Portrait', value: 'portrait' },
  timer:       { type: 'checkBox',                                                            title: 'Timer', value: 'timer' },
  tracking:    { type: 'checkBox',                                                            title: 'Tracking', value: 'tracking' },
  col_order:   { type: 'text',     maxCharL: 3,    widthInput: 80,   heightInput: inputHeight, title: 'Column Order', value: 'col_order', numbersOnly: true },
  level_1:     { type: 'text',     maxCharL: 30,   widthInput: 150,  heightInput: inputHeight, title: 'Level 1', value: 'level_1' },
  level_2:     { type: 'text',     maxCharL: 30,   widthInput: 150,  heightInput: inputHeight, title: 'Level 2', value: 'level_2' },
  level_3:     { type: 'text',     maxCharL: 30,   widthInput: 150,  heightInput: inputHeight, title: 'Level 3', value: 'level_3' },
}

const typeData = [
  {value: 'free', label: 'Free'},
  {value: 'locked', label: 'Locked'},
  {value: 'purchasable-movie', label: 'Purchasable Movie'},
  {value: 'purchasable-no_movie', label: 'Purchasable No Movie'},
  {value: 'linked', label: 'Linked'},
]

function compare( a, b ) {
    if ( a.label < b.label ){
      return -1;
    }
    if ( a.label > b.label ){
      return 1;
    }
    return 0;
  }

const NewTextInput = (props) => {

  const text = props.selectedWorkout[props.d.value];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'space-around', justifyContent: 'space-around', marginBottom: 8}}>
      <div style={{ fontSize: 10, color: 'rgba(0,0,0,0.75)', marginLeft: 5 }}>
        {props.d.title}
      </div>
      <div>
        {props.d.type === 'date' &&
        <DatePicker
          dateFormat="dd-MM-yyyy"
          selected={(props.d.type === 'date' && text !== undefined) ? new Date(text * 1000) : null}
          onChange={(e)=>props.changeText(Math.round(Date.parse(e)/1000), props.d.value)}
        />
        }
        {props.d.type === 'checkBox' &&
          <Input
            onChange={(e)=> props.changeText(e.target.checked === true ? 1 : 0, props.d.value)}
            checked={text === undefined || text === null ? ' ' : text}
            style={{ width: props.d.widthInput, height: props.d.heightInput, marginLeft: 1 }}
            type="checkbox"
          />
        }
        {props.d.type === 'text' &&
        <Input
          onChange={(e) => props.changeText(e.target.value, props.d.value)}
          maxLength={props.d.maxCharL}
          style={{ width: props.d.widthInput, height: props.d.heightInput, fontSize: 12, paddingLeft: 4, backgroundColor: props.disabled ? 'rgba(0,0,0,0.2)' : null }}
          value={text === undefined || text === null ? ' ' : text}
          type={props.d.multiline ? 'textarea' : props.d.numbersOnly ? 'number' : 'text'}
          disabled={props.disabled}
          />
        }

      </div>
    </div>
  )
}



const DropDown = (props) => {
  let value = props.selectedWorkout[props.d.value];

  if (value === null) {
    value = '';
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'space-around', justifyContent: 'space-around', marginBottom: 10}}>
      <div style={{ fontSize: 10, color: 'rgba(0,0,0,0.75)', marginLeft: 5 }}>
        {props.d.title}
      </div>
      <div>
        <select
          onChange={(e) => props.changeText(e.target.value, props.d.value)}
          name="type"
          id="mySelect"
          style={{ borderColor: 'rgba(0,0,0,0.2)', fontSize: 12, borderRadius: 3, color: '#464D69', backgroundColor: props.disabled ? 'rgba(0,0,0,0.2)' : null }}
          value={value}
          disabled={props.disabled}
        >
        {props.options.map((item, index) => {
          return (
            <option key={index} value={item.value}>{item.label}</option>
          )
        })}

        </select>
      </div>
    </div>
  );
}


const buttonList = [
        ['undo', 'redo'],
        ['fontSize', 'formatBlock'],
        '/',
        ['fontColor', 'hiliteColor'],
        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
        ['removeFormat'],
        '/', // Line break
        ['outdent', 'indent'],
        ['align', 'horizontalRule', 'list', 'lineHeight'],
        ['table', 'link'],
        /** ['imageGallery'] */ // You must add the "imageGalleryUrl".
        ['preview'],

    ]




export default class Modal extends Component {

  constructor(props) {
     super(props);
     this.state = {
       //startDate: new Date(),
       loading: false,
       uploadProgressPerc: 0,
       //origVideoList: this.createVideoList(),
       didWeUploadThumbnail: false,
       didWeUploadAttachment: false,
     };
   }

  changeText = (e, b) => {

    //Whenever we load the tracking data this value needs to be set to true so it all loads properly
    if (b==='tracking') {
      this.props.setTrackingFirstLoadTrue();
    }
    this.props.changeText(e, b)
  }


  createVideoList() {
    if (this.props.videoList === null ) {
      return [{value: '-', label: 'Use Vimeo ID'}];
    }
    const tmp = this.props.videoList.map(function(item) {
      return {value: item.video, label: item.video}
    });
    tmp.push({value: '-', label: 'Use Vimeo ID'});
    return tmp;
  }

  createAllIDs() {
    const tmp = this.props.origWorkouts.map(function(item) {
      return {value: item.uid, label: item.title}
    });
    tmp.push({value: '-', label: '-'});
    tmp.sort(compare);
    return tmp;
  }

  createAllStoreSkus() {
    //Get all the store_skus from the data, if there is a '-' then return false
    const tmp = this.props.origWorkouts.map(function(item) {
      if (item.store_sku !== '-') {
        return {value: item.store_sku, label: item.store_sku}
      }
      return false;
    });

    tmp.push({value: '-', label: '-'});
    //Only return where we have an actual store_sku (filter out the false)
    const dataR =  tmp.filter(function(item) {
      return item !== false;
     });

  //Remove duplicates (get just unique values )
   const unique = dataR.filter((v,i,a)=>a.findIndex(t=>(t.value === v.value))===i)

   return unique;
  }

  checkDataBeforeClose() {
    const type = this.props.selectedWorkout.type;
    const link_id = this.props.selectedWorkout.link_id;
    const store_sku = this.props.selectedWorkout.store_sku;

    if (type === 'linked' && (link_id === '-' || link_id === '')) {
      alert(`If you have a Type of 'Linked' selected you must choose a link ID`);
      return false;
    }

    if ((type === 'purchasable-movie' || type === 'purchasable-no_movie') && (store_sku === '-' || store_sku === '')) {
      alert(`If you have a purchasable Type selected you must choose a Store SKU`);
      return false;
    }


    if (this.props.selectedWorkout.image_key === '-' && this.props.selectedWorkout.video_key !== '-') {
      alert(`You must upload an image if you are not using the Vimeo ID`);
      return false;
    }

    /*
      if (this.props.selectedWorkout.vimeo_id === '' && this.props.selectedWorkout.video_key !== '-') {
        alert(`You must upload an image if you are not using the Vimeo ID`);
        return false;
      }
      */
    //Loop through and see if there's any empty inputs
    let doBreak = false;
    Object.keys(this.props.selectedWorkout).forEach((key, index) => {
      if (this.props.selectedWorkout[key] === '' && doBreak === false && key !== 'thumbnailUrl' && key !== 'level_1' && key !== 'level_2' && key !== 'level_3' ) {

        if (this.props.selectedWorkout.vimeo_id === '' && this.props.selectedWorkout.video_key !== '-') {

        } else {
          alert(`You must fill in ${key}`);
          doBreak = true;
        }

      }
    });

    if (doBreak) {
      return false;
    }

    return true;
  }

  updateTrackingData = (values) => {
    //This is the names for each row
    const data2Pass = {
      weeks: values.weeks,
      rows: values.rows,
      w1: values.w1,
      w2: values.w2,
      w3: values.w3,
      w4: values.w4,
      w5: values.w5,
      w6: values.w6,
      w7: values.w7,
      w8: values.w8,
      w9: values.w9,
      w10: values.w10,
      w11: values.w11,
      w12: values.w12,
      w13: values.w13,
      w14: values.w14,
      exerciseUID: this.props.selectedWorkout.uid
    }

    this.setState({ trackingDataToSave: data2Pass});
    //These are the reps for each week
    const trackingDataReps = {
      w1: JSON.stringify(values.w1rep),
      w2: JSON.stringify(values.w2rep),
      w3: JSON.stringify(values.w3rep),
      w4: JSON.stringify(values.w4rep),
      w5: JSON.stringify(values.w5rep),
      w6: JSON.stringify(values.w6rep),
      w7: JSON.stringify(values.w7rep),
      w8: JSON.stringify(values.w8rep),
      w9: JSON.stringify(values.w9rep),
      w10: JSON.stringify(values.w10rep),
      w11: JSON.stringify(values.w11rep),
      w12: JSON.stringify(values.w12rep),
      w13: JSON.stringify(values.w13rep),
      w14: JSON.stringify(values.w14rep),
      exerciseUID: this.props.selectedWorkout.uid
    }

      this.setState({ trackingDataToSaveReps: trackingDataReps});

  }


  saveWorkout = async () => {
    this.setState({ loading: true });

    const ret = await apiCall('insertWorkout', this.props.selectedWorkout);

    if (ret === false) {
      this.setState({ loading: false });
      alert('Error with inputted data, possible wrong vimeo ID or couldnt find the thumbnail');
    } else {
      //Save tracking data
      if (this.state.trackingDataToSave !== undefined) {
        await apiCall('saveMasterTrackingAdmin', this.state.trackingDataToSave);
        await apiCall('saveMasterTrackingAdminReps', this.state.trackingDataToSaveReps);

      }


      if (this.state.didWeUploadAttachment !== false) {//If here we have uploaded an attachment so need to delete old one
        await deleteAttachment(this.state.didWeUploadAttachment.old) //Delete the new attachment we just uploaded
        this.setState({ didWeUploadAttachment: false }) //Change flag back to false for when we open modal again
      }

      if (this.state.didWeUploadThumbnail !== false) {
        await deleteThumbnail(this.state.didWeUploadThumbnail.old)
        this.setState({ didWeUploadThumbnail: false })
      }
      this.setState({ loading: false });
      this.props.closeModal(true)
    }
  }

  closeModal = async () => {

    if (this.state.didWeUploadAttachment !== false) { //If here then we have uploaded an attachment, but we have hit cancel so need to delete newly uploaded one
      await deleteAttachment(this.state.didWeUploadAttachment.new) //Delete the new attachment we just uploaded
      this.props.changeText(this.state.didWeUploadAttachment.old, 'attachment_key'); //Set the text back to the old key
      this.setState({ didWeUploadAttachment: false }) //Change flag back to false for when we open modal again
    }

    if (this.state.didWeUploadThumbnail !== false) {
      await deleteThumbnail(this.state.didWeUploadThumbnail.new)
      this.props.changeText(this.state.didWeUploadThumbnail.old, 'image_key');
      this.setState({ didWeUploadThumbnail: false })
    }
    this.props.closeModal(false)

  }

  content = () => {
    if (this.state.loading) {
      return (
        <div>
        <img src={LoadingSpinner} style={{ width: '6.5rem', marginTop: 150, position: 'absolute' }} alt="loading..." />
        {this.state.uploadProgressPerc>0 &&
          <span style={{ color: 'rgba(0,0,0,0.6)'}}>{this.state.uploadProgressPerc}%</span>
        }
        </div>
      );
    } else {
      return (
        <div style={{ padding:10, justifyContent: 'center', alignItems: 'flex-start', backgroundColor: 'white', overflow: 'auto', borderRadius: 5}}>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
            <div>
              <NewTextInput d={data.vimeo_id} selectedWorkout={this.props.selectedWorkout} changeText={this.changeText.bind()} disabled={this.props.selectedWorkout.video_key !== '-'}/>
              <DropDown d={data.video_key} options={this.createVideoList()} selectedWorkout={this.props.selectedWorkout} changeText={this.changeText.bind()}/>

              <NewTextInput d={data.date} selectedWorkout={this.props.selectedWorkout} changeText={this.changeText.bind()}/>
              <NewTextInput d={data.title} selectedWorkout={this.props.selectedWorkout} changeText={this.changeText.bind()}/>
              <NewTextInput d={data.intensity} selectedWorkout={this.props.selectedWorkout} changeText={this.changeText.bind()}/>
              <NewTextInput d={data.equipment} selectedWorkout={this.props.selectedWorkout} changeText={this.changeText.bind()}/>
              <NewTextInput d={data.duration} selectedWorkout={this.props.selectedWorkout} changeText={this.changeText.bind()}/>
              <NewTextInput d={data.trainer} selectedWorkout={this.props.selectedWorkout} changeText={this.changeText.bind()}/>
              <NewTextInput d={data.tag} selectedWorkout={this.props.selectedWorkout} changeText={this.changeText.bind()}/>
              <DropDown d={data.type}      options={typeData}                selectedWorkout={this.props.selectedWorkout} changeText={this.changeText.bind()}/>
              <DropDown d={data.link_id}   options={this.createAllIDs()}       selectedWorkout={this.props.selectedWorkout} changeText={this.changeText.bind()} disabled={this.props.selectedWorkout.type !== 'linked'}/>
              <DropDown d={data.store_sku} options={this.createAllStoreSkus()} selectedWorkout={this.props.selectedWorkout} changeText={this.changeText.bind()} disabled={!(this.props.selectedWorkout.type === 'purchasable-movie' || this.props.selectedWorkout.type === 'purchasable-no_movie')}/>
              <NewTextInput d={data.col_order} selectedWorkout={this.props.selectedWorkout} changeText={this.changeText.bind()}/>

              <div style={{display: 'flex', width: 200, justifyContent: 'space-between'}} >
                <NewTextInput d={data.draft} selectedWorkout={this.props.selectedWorkout} changeText={this.changeText.bind()}/>
                <NewTextInput d={data.portrait} selectedWorkout={this.props.selectedWorkout} changeText={this.changeText.bind()}/>
              {trackingAndTimer &&
              <>
                <NewTextInput d={data.timer} selectedWorkout={this.props.selectedWorkout} changeText={this.changeText.bind()}/>
                <NewTextInput d={data.tracking} selectedWorkout={this.props.selectedWorkout} changeText={this.changeText.bind()}/>
              </>
              }
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: 250, marginTop: 45 }}>
                <Button variant="contained" onClick={()=>{
                  if (this.checkDataBeforeClose()) {
                    this.saveWorkout();
                  }
                }}
                  color="success">Save</Button>
                <Button variant="contained" onClick={()=>this.closeModal()}>Cancel</Button>
                <Button variant="contained" color="danger" onClick={()=>this.props.deleteWorkout()}>Delete</Button>
              </div>
            </div>


            <div style={{marginLeft: 10 }}>
              <NewTextInput d={data.description} selectedWorkout={this.props.selectedWorkout} changeText={this.changeText.bind()}/>

              <div style={{ display: 'flex', justifyContent: 'space-around'}}>
                <div style={{ }}>
                {pdf &&
                  <UploadAttachment
                    selectedWorkout={this.props.selectedWorkout}
                    changeText={this.props.changeText}
                    didWeUploadAttachment={(e)=>this.setState({ didWeUploadAttachment: e })}
                  />
                  }

                  <UploadThumbnail
                    selectedWorkout={this.props.selectedWorkout}
                    changeText={this.props.changeText}
                    didWeUploadThumbnail={(e)=>this.setState({ didWeUploadThumbnail: e })}
                  />

                  {this.props.selectedWorkout.tracking === 1 && trackingAndTimer &&

                    <Tracking
                      exerciseUID={this.props.selectedWorkout.uid}
                      trackingData={this.props.trackingData.filter(item => item.userID === 'Admin' && item.exerciseUID === this.props.selectedWorkout.uid)}
                      trackingDataReps={this.props.trackingData.filter(item => item.userID === 'Admin-Reps' && item.exerciseUID === this.props.selectedWorkout.uid)}
                      updateTrackingData={this.updateTrackingData}
                      trackingFirstLoad={this.props.trackingFirstLoad}
                      setFirstLoadFalse={this.props.setTrackingFirstLoadFalse}
                      />
                  }
                </div>
                {multiTagLevels &&
                <div style={{ }}>
                  <NewTextInput d={data.level_1} selectedWorkout={this.props.selectedWorkout} changeText={this.changeText.bind()}/>
                  <NewTextInput d={data.level_2} selectedWorkout={this.props.selectedWorkout} changeText={this.changeText.bind()}/>
                  <NewTextInput d={data.level_3} selectedWorkout={this.props.selectedWorkout} changeText={this.changeText.bind()}/>
                </div>
                }
              </div>
            </div>
              {/*
              <div style={{ fontSize: 10, color: 'rgba(0,0,0,0.75)', marginLeft: 5 }}>{'Description'}</div>
              <SunEditor
                onChange={(e) => this.props.changeText(e,'description')}
                setContents={this.props.selectedWorkout.description}
                setOptions={{
                      //height: "900",
                      //width: "900",
                      buttonList: buttonList,
                      mode: "classic",
                      katex: "window.katex",
                      width: "1000",
                      minHeight: "500",
                    }}
              />
              */}


          </div>
        </div>
      );
    }
  }

  render() {

   return (
      <Auxx>
         <div
            className={(this.props.show ? "movie-modal showEditor" : "movie-modal hide")}
            style={{
              width: '100%',
              height: '100%',
              top: 0,
              flex: 1,
              display: 'flex',
              backgroundColor: 'rgba(0,0,0,0.75)',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {this.content()}
         </div>
      </Auxx>
     )
   }

}
