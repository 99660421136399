import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import NavigationItem from 'Components/NavigationItem'
import DropdownContent from "Components/DropdownContent";
import DropdownArrow from 'Assets/images/drop-down-arrow.svg';
import { Input} from 'reactstrap';
import * as actions from '../actions';
import { signOut } from 'CommonFunctions';
import { whiteLabel } from 'Whitelabel';

const logo = whiteLabel('logo');
const highlightColour = whiteLabel('highlightColour');

const purchases = localStorage.getItem('purchases')

var buttonStyle = {
    backgroundColor: highlightColour,
    //backgroundImage: "linear-gradient(to bottom, #23a1d1, #1f90bb)",
    //backgroundRepeat: "repeat-x",
    //borderColor: "#1f90bb #1f90bb #145e7a",
    borderColor: 'white',
    padding: 4,
    color: "#ffffff",
    textShadow: "0 -1px 0 rgba(0, 0, 0, 0.25)",
    borderRadius: 5,
}

class navigation extends Component {

  state = {
    scrolling: false,
    origLevels: null,
    dropDownValue: [],
    searchBoxValue: '',
  }

  UNSAFE_componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);

  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  /** changes the scrolling state depending on the Y-position */
  handleScroll = (event) => {
    if (window.scrollY === 0) {
      this.setState({ scrolling: false });
    }
    else if (window.scrollY > 50) {
      this.setState({ scrolling: true });
    }
  }

  signOut = () => {
    signOut();
    //this.props.logoutUserFromCognito();
  }

  searchBox = () => {
    return (
      <Input
          onChange={(e) => this.filterVideos(e.target.value) }
          //onChange={(e) => pushButtonFunction(e.target.value)}
          value={this.state.searchBoxValue}
          style={{ fontSize: 12, width: 200, marginRight: 50 }}
          maxLength={40}
          autoComplete='none'
          id='inputA'
          placeholder='Search All Videos...'
      />
    );
  }

  filterVideos = (text) => {

    this.filterSection('All');
    this.setState({ dropDownValue: 'All', searchBoxValue: text });

    const tmp = this.props.workoutsOrig.filter(function(item) {
      return item['title'].toLowerCase().search(text.toLowerCase()) >= 0;
    });

    this.props.saveWorkouts(text === '' ? this.props.workoutsOrig : tmp);
    this.props.filterOn(text === '' ? false : true);
  }

  dropDownBox = () => {

    if (this.props.workoutsOrig !== undefined) {
      const levels = this.props.workoutsOrig.map(item => item.level_1);
      const levelsUnique = [...new Set(levels)]


      if (this.state.origLevels == null) {
        this.setState({ origLevels: ['All', ...levelsUnique] });
      }

      if (levelsUnique[0] !== '' && this.state.origLevels != null) {
        return (
          <select
            name="cars" id="cars"
            //style={{ borderWidth: 0 }}
            style={buttonStyle}
            value={this.state.dropDownValue}
            onChange={(e)=>this.filterSection(e.target.value)}
            >
            {this.state.origLevels.map(item =>
              <option key={item} value={item}>{item}</option>
            )}
          </select>)
      } else {
        return null
      }

    }
  }

  filterSection = (section) => {
    this.setState({ dropDownValue: section, searchBoxValue: '' });

    const tmp = this.props.workoutsOrig.filter(function(item) {
      return item['level_1'] === section;
    });

    this.props.saveWorkouts(section === 'All' ? this.props.workoutsOrig : tmp);

  }

  currentPlanPress = () => {
     alert('You currently don’t have an active plan. To gain access to all content upgrade your plan today via the mobile app.')
  }

  render() {
    const { scrolling } = this.state;

    return (
      <nav className={"navigation " + (scrolling ? "black" : "")} >
        <ul className="navigation__container">
          <NavigationItem link="/" exact>
            <img className="navigation__container--logo" style={{ marginTop: 0 }} src={logo} alt="" />
          </NavigationItem>
          <DropdownArrow className="navigation__container--downArrow-2"></DropdownArrow>
          {/*
          <div className="navigation__container-link pseudo-link">Home</div>
          */}
          <div className="navigation__container--left">

          {this.props.purchases.length === 0 &&
            <div onClick={this.currentPlanPress} style={{ backgroundColor: 'rgba(255,255,255,0.5)', borderRadius: 8, padding: 8, cursor: 'pointer'}}>
            <span style={{ color: 'rgba(0,0,0,0.75)'}}>Current Plan: </span>
            <span style={{ color: 'red'}}>None</span>
            </div>
          }

          </div>
          {/*this.dropDownBox()*/}
          <span style={{ width: 25 }}/>
          {this.searchBox()}
          <DropdownContent admin={this.props.admin} signOut={this.signOut} />
        </ul>
      </nav>
    )
  }
}

//export default navigation;

// map state to props
const mapStateToProps = (state) => {
   return {
     admin: state.authUser.admin,
     workouts: state.reducerData.workouts,
     workoutsOrig: state.reducerData.workoutsOrig,
     purchases: state.reducerData.purchases
   };
}

export default connect(mapStateToProps, actions)(navigation);
