import SingleMovie from './imageThumb';
import React from 'react';
//import moment from 'moment';
import _ from 'lodash';
import $ from 'jquery';

import LeftArrow from 'Assets/images/leftArrow.png';
import RightArrow from 'Assets/images/rightArrow.png';

import { whiteLabel } from 'Whitelabel';
const multiTagLevels = whiteLabel('multiTagLevels');

function compare( a, b ) {
    if ( a.col_order < b.col_order ){
      return -1;
    }
    if ( a.col_order > b.col_order ){
      return 1;
    }
    return 0;
  }

function scroll(direction, classNT){
  //Get the ID of the container the button we clicked on is associated with
  const classN = `.${classNT}`;

  //Get info about its dimensions
  let far = $( classN ).width()/2*direction;

  //Something else
  let pos = $(classN).scrollLeft() + far;

  //Slide the container
  $(classN).animate( { scrollLeft: pos }, 500);

}


function sortWorkoutsByRowOrder(moviesGroupByTag, rowOrder) {

    for (let i = 0; i < rowOrder.length; i++) {
      const tag = rowOrder[i].tag;
    }

    const y = rowOrder.map(item => {
      return moviesGroupByTag[item.tag];
    })

    const c = y.filter(item => item !== undefined);

    return c;

}


export function movieRows(allMovies, handleToggleModal, rowOrder) {
  return renderLevel1Sections(allMovies, handleToggleModal, rowOrder);
}

//Here we render a whole level 1 section
function renderLevel1Sections(allMovies, handleToggleModal, rowOrder) {
  //Get our level_1 values
  const level_1 = allMovies.map(item => item.level_1);
  let unique_levels = [...new Set(level_1)];

  return unique_levels.map(level1_name =>
    <div key={level1_name} style={{marginTop: 20}}>
      {/*<span style={{fontSize:36, marginBottom: 20, fontWeight: 600, marginLeft: -30}}>{level1_name}</span>*/}
      {renderTags(handleToggleModal, allMovies, level1_name)}
    </div>
  );

}

function renderTags(handleToggleModal, allMovies, level1_name) {
  const levelMovies = allMovies.filter(x => x.level_1 === level1_name)

  //Get our tags from these levelMovies
  const tags = levelMovies.map(item => item.tag);
  let unique_tags = [...new Set(tags)];

  return unique_tags.map(tag => renderImageThumbs(handleToggleModal, levelMovies, level1_name, tag));

}


function renderImageThumbs(handleToggleModal, levelMovies, level1, tag) {
  const tagMovies = levelMovies.filter(x => x.tag === tag)

  let movieRows = tagMovies.map((movie) => {
    if (movie.vimeo_id || movie.hlsAddress) {
      const movieComponent =
      <SingleMovie
        key={movie.uid}
        posterUrl={movie.thumbnailUrl}
        handleToggleModal={handleToggleModal}
        movie={movie}
        />
        return movieComponent;
    }
  })
/*
  const filler = <SingleMovie
    key={'movie.uid'}
    posterUrl={'movie.thumbnailUrl'}
    handleToggleModal={handleToggleModal}
    movie={'movie'} />


  movieRows.push(filler)
*/

  const key = level1.replace(/\s+/g, '') + tag.replace(/\s+/g, '');
  return (
    <div key={key} >
      <h1 style={{fontSize:20, marginTop: 20, marginBottom: 20}}>{tag}</h1>
      <div style={{ display: 'flex', flexDirection: 'row', height: 310}}>
        <a href="#/carousel/previous/" onClick={scroll.bind(null,-1, key)} style={{ marginRight: -0, alignSelf: 'center', zIndex: 10 }} className="prev" id="prev">
          <img style={{ width: 40, height: 40, opacity: 0.4 }} src={LeftArrow} alt="left_arrow" />
        </a>
        <div style={{ width: '100%', paddingLeft: 40, overflowY: 'visible', overflowX: 'scroll' }} className={`movieShowcase__container image-container ${key}`}>
          {movieRows}
        </div>
        <a href="#/carousel/previous/" onClick={scroll.bind(null,1, key)} style={{ marginLeft: -0, alignSelf: 'center', zIndex: 10 }} className="prev" id="prev">
          <img style={{ width: 40, height: 40, opacity: 0.4 }} src={RightArrow} alt="left_arrow" />
        </a>
      </div>
    </div>
  )
}

/*
export function movieRowsX(movies, handleToggleModal, rowOrder, level) {

  var moviesGroupByTag = _.chain(movies).groupBy('tag').value();
  console.log('moviesGroupByTag', moviesGroupByTag);

  const tmp = sortWorkoutsByRowOrder(moviesGroupByTag, rowOrder);
  console.log('tmp', tmp);


  return tmp.map((tagName, index) => {

    const movies = tagName.sort( compare ); //This sorts the workouts into their column order

    let movieRows = movies.map((movie) => {
      if (movie.vimeo_id || movie.hlsAddress) {
        const movieComponent = <SingleMovie
          key={movie.uid}
          posterUrl={movie.thumbnailUrl}
          handleToggleModal={handleToggleModal}
          movie={movie} />
          return movieComponent;
      }
    })

    if(_.compact(movieRows).length !== 0) {
      //console.log('movieRows', movieRows);
      //console.log('movieRows', movieRows[0].props.movie.uid, movieRows[0].props.movie.title, movieRows[0].props.movie.tag)
      //const key =  movieRows[0].props.movie.uid + (movieRows[1] === 'undefined' ? 'extra' : movieRows[1].props.movie.uid);
      //const key =  movieRows[0].props.movie.level_1 + ' - ' + movieRows[0].props.movie.tag;

      //const key = Math.round(Math.random()*10000);
      const key = level + '-' + movies[0].tag;
      //const key =  movieRows[0].props.movie.uid;
      //console.log('key', key);
      return (
        <div key={key} >
          <h1 style={{ fontSize: 20}}>{movies[0].tag}</h1>
          <div style={{ display: 'flex', flexDirection: 'row', height: 310}}>
            <a href="#/carousel/previous/" onClick={scroll.bind(null,-1, key)} style={{ marginRight: -0, alignSelf: 'center', zIndex: 10 }} className="prev" id="prev">
              <img style={{ width: 40, height: 40, opacity: 0.4 }} src={LeftArrow} alt="left_arrow" />
            </a>
            <div style={{ width: '100%', paddingLeft: 40, overflowY: 'visible', overflowX: 'scroll' }} className={`movieShowcase__container image-container ${key}`}>
              {movieRows}
            </div>
            <a href="#/carousel/previous/" onClick={scroll.bind(null,1, key)} style={{ marginLeft: -0, alignSelf: 'center', zIndex: 10 }} className="prev" id="prev">
              <img style={{ width: 40, height: 40, opacity: 0.4 }} src={RightArrow} alt="left_arrow" />
            </a>
          </div>
        </div>
      )
    }
  });
}
*/
