/**
 * Auth User Reducers
 */
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    LOGOUT_USER,
    SAVE_ADMIN,
} from '../actions/types';

import AppConfig from 'static/AppConfig';

/**
 * initial auth user
 */
const INIT_STATE = {
    owner: AppConfig.whitelabel,
    user: localStorage.getItem('user_id'),
    loading: false,
    confirm: false,
    admin: (localStorage.getItem('admin') === undefined || localStorage.getItem('admin') === "false") ? false : true,
    profileConfirmed: (localStorage.getItem('profile_confirmed') === undefined || localStorage.getItem('profile_confirmed') === "false") ? false : true
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case SAVE_ADMIN:
            return { ...state, admin: action.payload}

        case LOGIN_USER:
            return { ...state, loading: true };

        case LOGIN_USER_SUCCESS:
            return { ...state, loading: false, user: action.payload };

        case LOGIN_USER_FAILURE:
            return { ...state, loading: false };

        case LOGOUT_USER:
            return { ...state, user: null };

        default: return { ...state };
    }
}
