import React from 'react';

import PlayLogo from 'Assets/images/play-button.svg';

import { whiteLabel } from 'Whitelabel';

const headerText = whiteLabel('headerText');
const headerBackground = whiteLabel('headerBackground');

export default function Header(props) {
  const backgroundStyle = {
    backgroundSize: "cover",
    backgroundImage: `url(${headerBackground})`,
    backgroundPosition: "center",
  }

  return (
      <header style={backgroundStyle} className="header">
        <div className="header__container overlay_shadow_header">
          <h1 className="header__container-heading">Welcome...</h1>
          <button onClick={() => document.getElementById("movieShowcase").scrollIntoView()} className="header__container-btnPlay">
            <PlayLogo className="header__container-btnMyList-play" />
            Start
          </button>
          <p className="header__container-overview">{headerText}</p>
        </div>
        <div className="header--fadeBottom"></div>
      </header>
  )
}